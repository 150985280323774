import Swiper from "swiper/bundle";
import GET_ARTICLES from "../class/getArticles";
import { productsBlock } from "../class/products-block";

const [ w, d, mq ] = [window, document, window.matchMedia("(min-width:900px)") ];
const domReady = TPL => {
  new BLOCKS;
  new productsBlock;
  let Mv = document.querySelector('.mainvisual');
  w.addEventListener('load', function() {
    Mv.classList.add('loaded');
  });

  if(mq.matches){
    $(w).on('scroll', function(){
      const scroll = $(this).scrollTop();
      $('.mainvisual .item1').css('transform', 'translateY(' + - scroll * .1   + 'px)');
      $('.mainvisual .item2').css('transform', 'translateY(' + - scroll * .3   + 'px)');
      $('.block-buffken .parts').css('transform', 'translateY(' + - scroll * .1   + 'px)');
    });
  }
};

class BLOCKS {
  constructor() {
    this.news();
    this.mainvisual();
  }

  mainvisual() {
    //メインスライダー
    const mvSlide = new Swiper('.mv_slide[data-plugin="mainSlider"]',{
      loop: true,
      effect: 'fade',
      speed: 1200,
      fadeEffect: {
        crossFade: true, // クロスフェードを有効にする（フェードモードの場合 true 推奨）
      },
      autoplay:{
        delay: 6000,
        disableOnInteraction: false,
        waitForTransition: false
      },
      pagination: {
        el: ".mv-pager",
        clickable: true,
      },
    });
  }

  news () {
    // mainvisual news
    const news_ticker = new GET_ARTICLES('.mainvisual .news_wrap[data-plugin="getArticles"]');
    news_ticker.makeItem = (item,content) => `
    <a href="${item.href}">
      <p class="info">
        <strong>NEWS</strong>
        <span class="date">${item.date}</span>
        <span class="cate">${item.category.name}</span>
      </p>
      <span class="ttl"><span>${item.title}</span></span>
    </a>`;
    // news_ticker.makeItem = (item) => console.log(item);
    news_ticker.render();
    
    //NEWS
    const getArticle = new GET_ARTICLES('.block-news .news_list');
    getArticle.makeItem = (item,content) => `
    <li>
      <a${(item.is_new)? ' class="is-new"': ''} href="${item.href}">
        <figure><img src="${item.thumb}" alt="${item.title}"></figure>
        <div class="text_wrap">
          <p class="info"><span class="date">${item.date}</span><span class="cate">${item.category.name}</span></p>
          <p class="ttl"><span>${item.title}</span></p>
        </div>
      </a>
    </li>`;
    // getArticle.makeItem = (item) => console.log(item);
    getArticle.render();
  }
  
  fetchCategories (content) {
    return fetch(`/assets/api/getCategories/?content=${content}`)
      .then(r => r.json())
      .catch(e => { throw new Error(e)});
  }

  fetchArticles (opt){
    return fetch(`/assets/api/getArticles/?${ new URLSearchParams(opt)}`)
    .then(r => r.json())
    .catch(e => { throw new Error(e)});
  }
}

const jqInit = () => false;

export default function HOME () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );

  $(() => jqInit());
}