const [w,d] = [window, document];

const domReady = () => {
  $(w).on('scroll', function(){
    const soluStart = $('.block-solution').offset().top;
    const windowH = $(w).height();
    const soluEnd = soluStart + $('.block-solution').outerHeight();
    const scroll = $(this).scrollTop();
    if (scroll < soluStart){
      $('.fixed_bg').removeClass('fixed');
      $('.fixed_bg').removeClass('bottom');
    } else if (scroll + windowH >= soluEnd) {
      $('.fixed_bg').removeClass('fixed');
      $('.fixed_bg').addClass('bottom');
    } else {
      $('.fixed_bg').addClass('fixed');
      $('.fixed_bg').removeClass('bottom');
    }
  });
};

export default function BUFFKEN () {
  $(w).on('load', domReady);
}